import React from "react";
import { BACKEND_SERVER_ADDRESS } from "../../data/variables/variables-1";

const HeroSectionOfProjectDetailsPage = (props) => {
  const { project } = props;
  console.log("project",project)
  const { title, bannerImage, projectLogo } = project;
  return (
    <>
      <section className="md:mb-10">
        <div className="md:hidden">
          <div className="project-mobile-banner relative ">
            <img
              src={bannerImage}
              alt=""
              className="w-full h-full object-cover object-center"
            />
            <div className="bg-[black] h-full w-full absolute top-0 bg-opacity-[35%] object-cover object-center">
              <div className="relative  h-full">
                <div>
                  <div className="h-[9rem]   bg-gradient-to-b from-[#010101] to-transparent"></div>
                </div>
                <div className="mt-20">
                  <div className="flex items-center justify-center">
                    <div className="w-[323px] h-[187px]">
                      {/* <img
                        src={bannerImage}
                        alt=""
                        className="w-full h-full object-cover object-center"
                      />{" "} */}
                    </div>
                  </div>
                </div>
                <div className="text-2xl px-5 text-center mt-16 opacity-[0.65]">
                  {title}
                </div>
                {/* <div className="text-3xl px-5 text-center mt-3   ">
                  <span className="font-medium">contempo</span>
                  <span className="opacity-[0.8]">studio</span>
                </div> */}
                <div className="projectbannerlogo px-5 text-center mt-3">
                  <img
                    src={`${BACKEND_SERVER_ADDRESS}${projectLogo}`}
                    alt=""
                    className=""
                  />
                </div>

                <div className="h-[5rem] absolute w-full bottom-0    bg-gradient-to-b from-transparent to-[#131313]"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden md:block">
          <div className="relative">
            <img
              src={`${BACKEND_SERVER_ADDRESS}${bannerImage}`}
              alt=""
              className="w-full h-[50rem] object-cover object-center"
            />
            <div className="bg-[black] bg-opacity-[0%] absolute top-0 h-full w-full d-flex project-detail-hero-section">
              <div className="m-auto project-header-logo">
                <div className="text-[28px] px-5 text-center mt-16 opacity-[0.65]">
                  {title}
                </div>
                <div className="d-flex justify-content-center mt-[24px]">
                  <img
                    src={`${BACKEND_SERVER_ADDRESS}${projectLogo}`}
                    alt=""
                    className="h-[45px]"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HeroSectionOfProjectDetailsPage;
